body {
  font-family: 'Menlo', '微软雅黑', 'monospace', '黑体', 'Roboto', 'Helvetica Neue', Helvetica, Arial, sans-serif;
  color: #333;
}
#mainContainer> .content {
  padding: 0 20px 20px 20px;
}
paper-toast#toast {
  z-index: 10;
}
* {
  font-size: inherit;
}
:focus {
  outline: 0;
}
#quickPayMenuContainer paper-item, paper-menu a {
  font-size: 14px;
}
paper-button, iron-dropdown, .paper-button-1> a, .data-grid {
  font-size: 13px!important;
}

/* paper-input */

div.search-condition {
  margin: 10px auto;
}
div.search-condition paper-input, div.search-condition x-select, div.search-condition yun-biz-category-selector, div.search-condition yun-city-selector, div.search-condition yun-complete, div.search-condition yun-date-input {
  width: 170px;
  margin-right: 15px;
  display: inline-block;
}
div.search-condition paper-button, paper-button.colorful {
  -webkit-font-smoothing: auto;
  background: #05B0CF;
  color: #ffffff;
}
div.divider.blank {
  height: 9px;
}
div.divider.line {
  border-top: 1px solid #eee;
  margin: 9px 0;
}

.agent-edit-container #dropdown{
 position:absolute!important;
}

/* 定义 data-grid 样式 */

.table {
  display: table;
  width: 100%;
}
.table .thead, .table .tbody, .table .tfoot {
  display: table-row-group;
}
.table .tr {
  display: table-row;
}
.table .th {
  font-weight: bold;
  text-align: center;
}
.table .th, .table .td {
  display: table-cell;
  width: 1%;
}
.table.data-grid {
  width: 100%;
  display: block;
  overflow-x: auto;
  border-spacing: 0;
  border-collapse: collapse;
  border: 1px solid #ddd;
  margin: 10px auto;
}
.table.data-grid tr {
  line-height: 1.42857143;
}
.table.data-grid .th, .table.data-grid .td {
  width: 1%;
  vertical-align: middle;
}
.table.data-grid .th, .table.data-grid .td {
  white-space: nowrap;
  padding: 6px;
}
.table.data-grid .wedge {
  padding: 3px;
}
.table.data-grid.low .th, .table.data-grid.low .td {
  padding: 3px 8px;
}
.table.data-grid> .thead> .tr, .table.data-grid> .tbody {
  border-bottom: 1px solid #ddd;
}
.table.data-grid> .tfoot> .tr, .table.data-grid> .tbody {
  border-top: 1px solid #ddd;
}
.table.data-grid> .wedge {
  text-align: right;
}
.table.data-grid> .tbody> .tr:hover, .table.data-grid .tr.hover:hover {
  background-color: rgba(0, 188, 212, 0.17) !important;
}
.table.data-grid> .tbody> .tr:nth-child(even) {
  background-color: #f9f9f9;
}
.table.data-grid iron-icon {
  height: 1.2em !important;
}
.table.data-grid> .tbody> .tr> .th {
  text-align: left;
}
.table.data-grid .tr.pagination {
  height: 30px;
}
.table.data-grid .tr.pagination> * {
  position: absolute;
  right: 24px;
  line-height: 30px;
}

/* code 样式 */

pre, code {
  font-family: Consolas, "Liberation Mono", Menlo, Courier, monospace;
  font-size: 10px;
  margin: 0;
  padding: 3px;
}
paper-menu a> *, paper-menu paper-item> *, paper-menu paper-icon-item> * {
  pointer-events: none;
}

/* mouse */

.hand {
  cursor: pointer;
}
.nowrap {
  white-space: nowrap;
}

/* colors */

.red {
  color: #D50000;
}
.green {
  color: #00C853;
}
.yellow {
  color: #E65100;
}
.blue {
  color: #0000FF;
}
.primary {
  color: #00BCD4;
}

/* align */

.left {
  text-align: left !important;
}
.right {
  text-align: right !important;
}
.center {
  text-align: center !important;
}
.paper-button-1 {
  display: inline-block;
  position: relative;
  box-sizing: border-box;
  min-width: 5.14em;
  margin: 0 0.29em;
  background: #05B0CF;
  text-align: center;
  font: inherit;
  text-transform: uppercase;
  outline-width: 0;
  border-radius: 3px;
  -moz-user-select: none;
  -ms-user-select: none;
  -webkit-user-select: none;
  user-select: none;
  cursor: pointer;
  z-index: 0;
  padding: 0.6em 0.57em;
}
.abbr {
  border-bottom: 1px dotted #777;
}
a {
  color: #337ab7;
  text-decoration: none;
  background-color: transparent;
  cursor: pointer;
}
.wxmodel {
  color: #000000;
  text-decoration: none;
  background-color: transparent;
  cursor: pointer;
}
a:focus, a:hover {
  color: #23527c;
  text-decoration: underline;
  outline: 0;
}
paper-dialog .buttons> paper-button:hover, paper-dialog .buttons> paper-button:hover {
  background-color: rgba(0, 188, 212, 0.25);
}
paper-dialog.paper-date-picker-dialog .buttons> paper-button:hover, paper-dialog.paper-time-picker-dialog .buttons> paper-button:hover {
  background-color: rgb(0, 188, 212);
}
paper-date-picker {
  margin-top: 0 !important;
  padding: 0!important;
}
*> i18n-msg {
  font-size: inherit;
}
#splash {
  position: fixed;
  left: 0;
  right: 0;
  top: 0;
  bottom: 0;
  background-color: #00BCD4;
}
#splash .spinner {
  width: 100px;
  height: 100px;
  position: absolute;
  margin: auto;
  left: 0;
  right: 0;
  top: 0;
  bottom: 0;
  background-color: #ff4081;
  border-radius: 100%;
  -webkit-animation: sk-scaleout 1.0s infinite ease-in-out;
  animation: sk-scaleout 1.0s infinite ease-in-out;
}
@-webkit-keyframes sk-scaleout {
  0% {
    -webkit-transform: scale(0)
  }
  100% {
    -webkit-transform: scale(1.0);
    opacity: 0;
  }
}
@keyframes sk-scaleout {
  0% {
    -webkit-transform: scale(0);
    transform: scale(0);
  }
  100% {
    -webkit-transform: scale(1.0);
    transform: scale(1.0);
    opacity: 0;
  }
}
